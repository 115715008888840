import React from "react";
import { Spin } from "antd";
import { Link } from "react-router-dom";

export const UserProfile = ({
  loading = false,
  user,
  backTo,
  actions = "",
}) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Spin spinning={loading}>
            <div className="card">
              <div className="account-details">
                <div className="account-details-colum">
                  <h6>Full Name</h6>
                  <p>{user?.firstname + " " + user?.lastname}</p>
                </div>
                <div className="account-details-colum">
                  <h6>Email</h6>
                  <p>{user?.email}</p>
                </div>
                <div className="account-details-colum">
                  <h6>Instagram profile Link</h6>
                  <p>
                    <a
                      href={user?.instagramProfile}
                      target="_blank"
                      style={{ color: "inherit", textDecoration: "underline" }}
                    >
                      {user?.instagramProfile}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Spin>
          {actions}
        </div>
      </div>
    </div>
  );
};
