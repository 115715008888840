import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import {
  CREATE_COUPON,
  FETCH_MY_COUPON,
  UPDATE_COUPON_STATUS,
} from "../graphql/modules";
import {
  Modal,
  Input,
  Popconfirm,
  Pagination,
  Select,
  Tag,
  Button,
} from "antd";
import { DynamicList } from "../components/Shared";
import { errorNotify, getFile, successNotify, warnNotify } from "../util";
import { Fragment } from "react";
import moment from "moment";
import { couponStatus } from "../config";
import { EyeOutlined } from "@ant-design/icons";

export const MyCoupons = () => {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [visible, setVisible] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [filterData, setFilterData] = useState({});
  const { data, loading, error, refetch } = useQuery(FETCH_MY_COUPON, {
    variables: {
      limit,
      offset: page - 1,
      ...filterData,
    },
    fetchPolicy: "no-cache",
  });

  const couponList = data?.FetchMyCoupons?.result || [];
  const total = data?.FetchMyCoupons?.count || 0;

  // create category api
  const [CreateCouponMutation, { loading: createLoading }] =
    useMutation(CREATE_COUPON);
  const createCategory = async () => {
    try {
      const {
        data: { CreateCoupne },
      } = await CreateCouponMutation({
        variables: {
          couponCode,
        },
      });
      if (CreateCoupne.success) {
        successNotify(CreateCoupne.message);
        closeModal();
        refetch();
      } else {
        warnNotify(CreateCoupne.message);
      }
    } catch (error) {}
  };

  // handle modal
  const closeModal = () => {
    setCouponCode("");
    setVisible(false);
  };

  // delete category
  const [DeleteCouponMutation] = useMutation(UPDATE_COUPON_STATUS);
  const delteCoupon = async (id) => {
    console.log(id);
    try {
      const {
        data: { UpdateCoupneStatus },
      } = await DeleteCouponMutation({
        variables: {
          couponId: id,
          status: "Deleted",
        },
      });
      if (UpdateCoupneStatus.success) {
        successNotify(UpdateCoupneStatus.message);
        refetch();
      } else {
        warnNotify(UpdateCoupneStatus.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  const columns = [
    // {
    //   title: "#",
    //   render: (_text, _row, index) => index + 1,
    // },
    {
      title: "Coupon Code",
      dataIndex: "code",
    },
    {
      title: "Coupon Used",
      dataIndex: "usedCount",
    },
    // {
    //   title: "Money Earned",
    //   dataIndex: "moneyEarned",
    // },
    {
      title: "Discount(%)",
      dataIndex: "discountInPercent",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value, { validUntil }) => (
        <CouponStatus text={value} validUntil={validUntil} />
      ),
    },
    {
      title: "Valid Until",
      dataIndex: "validUntil",
      render: (value) =>
        value !== "0"
          ? moment(Number(value)).format("YYYY-MM-DD_HH:mm")
          : "N/A",
    },
    // {
    //   title: "Created At",
    //   dataIndex: "createdAt",
    //   render: (value) => moment(Number(value)).format("YYYY-MM-DD_HH:mm"),
    // },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => (
        <Fragment>
          {/* <i
            className="fas fa-pencil mr-2"
            onClick={() => openModal(record)}
            style={{ cursor: "pointer" }}
          ></i> */}
          <Popconfirm
            title="Are you sure?"
            okText="Delete"
            okType="danger"
            onConfirm={() => delteCoupon(record._id)}
          >
            <i className="fas fa-trash mr-2" style={{ cursor: "pointer" }}></i>
          </Popconfirm>
          {/* <Link to={`/categories/${record?._id}`}>
            <i className="fas fa-eye" style={{ cursor: "pointer" }}></i>
          </Link> */}
        </Fragment>
      ),
    },
  ];
  // page handler
  const onChangePagination = (p) => {
    setPage(p);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };
  // status change function
  const handleStataus = (value) => {
    if (!value) {
      delete filterData.status;
      setFilterData({ ...filterData });
    } else {
      setFilterData({ ...filterData, status: value });
    }
  };

  const searchComponent = (
    <div className="d-flex justify-content-between align-items-center">
      <Input
        onChange={handleInput}
        name="code"
        value={filterData.code}
        size="large"
        className="mr-2"
        placeholder="Search by coupon code"
      />
      <Select
        style={{ width: "100%" }}
        className="mr-2"
        value={filterData.status || ""}
        size="large"
        onChange={handleStataus}
      >
        <Select.Option value="">All</Select.Option>
        {Object.keys(couponStatus).map(
          (item) =>
            item !== couponStatus.Deleted && (
              <Select.Option value={couponStatus[item]}>
                {couponStatus[item]}
              </Select.Option>
            )
        )}
      </Select>
    </div>
  );

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  let createBtn = (
    <Fragment>
      <button
        onClick={() => setVisible(true)}
        className="btn-common"
        type="primary"
      >
        <i className="fas fa-plus-circle mr-2"></i> Create Coupon
      </button>
    </Fragment>
  );

  return (
    <Fragment>
      <DynamicList
        title="List of Coupons"
        rowKey={(row) => row._id}
        loading={loading}
        columns={columns}
        dataSource={couponList || []}
        tableFilter={searchComponent}
        actions={createBtn}
        expandable={{
          expandedRowRender: (record) => <ExpandedComponet {...record} />,
        }}
        pagination={
          <Pagination
            className="pagination"
            total={total}
            pageSize={limit}
            current={page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        }
      />
      <Modal
        title={"Add new coupon"}
        visible={visible}
        onOk={createCategory}
        okText="Create"
        okButtonProps={{
          disabled: !couponCode,
          loading: createLoading,
        }}
        onCancel={closeModal}
      >
        <Input
          size="large"
          value={couponCode}
          placeholder="Coupon code"
          onChange={(e) => setCouponCode(e.target.value)}
        />
      </Modal>
    </Fragment>
  );
};

const ExpandedComponet = ({
  createdAt,
  moneyEarned,
  desc,
  evidenceLink,
  cancellationNote,
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="row">
      <Modal
        title="Evidence image"
        visible={visible}
        onOk={() => setVisible(false)}
        cancelButtonProps={{ className: "d-none" }}
        onCancel={() => setVisible(false)}
      >
        <img src={getFile(evidenceLink)} alt="" />
        <p className="mt-3 mb-0">
          <b className="mr-2">Description :</b>
          {desc || "N/A"}
        </p>
      </Modal>
      <div className="col-md-3">
        <p>
          <b className="mr-2">Created at:</b>
          {moment(Number(createdAt)).format("YYYY-MM-DD_HH:mm")}
        </p>
      </div>
      <div className="col-md-3">
        <p>
          <b className="mr-2">Money earned:</b>${(moneyEarned || 0).toFixed(2)}
        </p>
      </div>
      <div className="col-md-3">
        <p className="d-flex align-items-center">
          {evidenceLink && (
            <Button className="mr-2" onClick={() => setVisible(true)}>
              See Evidence
            </Button>
          )}
          {/* {evidenceLink ? (
            <>
              {evidenceLink?.split("-")[2]}
              <EyeOutlined
                className="ml-2"
                style={{ fontSize: "20px" }}
                onClick={() => setVisible(true)}
              />
            </>
          ) : (
            "N/A"
          )} */}
        </p>
      </div>
      {/* <div className="col-md-6">
        <p>
          <b className="mr-2">Description :</b>
          {desc || "N/A"}
        </p>
      </div> */}
      {cancellationNote && (
        <div className="col-md-12">
          <p>
            <b className="mr-2">Cancellation note :</b>
            {cancellationNote || "N/A"}
          </p>
        </div>
      )}
    </div>
  );
};

const CouponStatus = ({ text, validUntil }) => {
  if (validUntil < Date.now() && couponStatus.Accepted === text) {
    return <Tag color="volcano">Expired</Tag>;
  }
  switch (text) {
    case couponStatus.Denied:
      return <Tag color="red">{text}</Tag>;
    case couponStatus.Accepted:
      return <Tag color="geekblue">{text}</Tag>;
    case couponStatus.Pending:
      return <Tag color="orange">{text}</Tag>;
    case couponStatus.Paused:
      return <Tag color="magenta">{text}</Tag>;
    case couponStatus.Paid:
      return <Tag color="green">{text}</Tag>;
    default:
      return <Tag color="green">{text}</Tag>;
  }
};
