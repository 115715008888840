import gql from "graphql-tag";

export const CREATE_COUPON = gql`
  mutation CreateCoupne($couponCode: String) {
    CreateCoupne(value: $couponCode) {
      code
      success
      message
    }
  }
`;

export const UPDATE_COUPON_STATUS = gql`
  mutation UpdateCoupneStatus($status: CouponStatus, $couponId: ID) {
    UpdateCoupneStatus(status: $status, couponId: $couponId) {
      code
      success
      message
    }
  }
`;
export const FETCH_MY_COUPON = gql`
  query FetchMyCoupons(
    $limit: Int
    $offset: Int
    $status: CouponStatus
    $code: String
  ) {
    FetchMyCoupons(
      limit: $limit
      offset: $offset
      status: $status
      code: $code
    ) {
      count
      result {
        _id
        code
        usedCount
        validUntil
        moneyEarned
        desc
        evidenceLink
        discountInPercent
        cancellationNote
        status
        createdAt
      }
    }
  }
`;
