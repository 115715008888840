export const sidebar = [
  // {
  //   path: "/customers",
  //   label: "Customers",
  //   icon: "far fa-users",
  // },
  // {
  //   path: "/orders",
  //   label: "Orders",
  //   icon: "fab fa-sellsy",
  // },
  {
    label: "My coupons",
    icon: "fab fa-bitbucket",
    path: "/my-coupons",
  },
  // {
  //   label: "Settings",
  //   icon: "fas fa-cog",
  //   path: "/settings"
  // },
  // {
  //   label: "Landing Page",
  //   icon: "fas fa-plane",
  //   path: "/landing-page"
  // },
  // {
  //   label: "Content Management",
  //   icon: "fas fa-tasks",
  //   path: "/content-management"
  // },
  // {
  //   path: "/faq",
  //   label: "FAQ",
  //   icon: "far fa-question-circle",
  // },
  {
    path: "/my-profile",
    label: "Account",
    icon: "far fa-user",
  },
];
