// export const ORIGIN = "localhost:8000/graphql/";
// export const ORIGIN = "devapi.chillfitrave.com/graphql";
export const ORIGIN = "api.chillfitrave.com/graphql";

// export const ORIGIN = "https://api.chillfitrave.com/graphql/"
export const GRAPHQL_URL = `https://${ORIGIN}`;
// export const GRAPHQL_URL = `http://192.168.0.15:8000/graphql`;
export const GRAPHQL_URL_WS = `ws://${ORIGIN}`;
export const S3_BASE_URL = "https://d3rf8scqf54r2k.cloudfront.net";

export const OrderStatus = {
  PreOrder: "Pre order",
  Pending: "Preparing to ship",
  Shipped: "Shipped",
  Delivered: "Delivered",
  Canceled: "Canceled",
  Deleted: "Deleted",
};

export const couponStatus = {
  Pending: "Pending",
  Accepted: "Accepted",
  Paused: "Paused",
  Paid: "Paid",
  Canceled: "Canceled",
  Denied: "Denied",
  Expired: "Expired",
  Deleted: "Deleted",
};
