import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthLayout } from "../containers";

// auth component
export const AuthRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  return isAuthenticated ? (
    <Redirect to="/my-coupons" />
  ) : (
    <Route
      {...rest}
      component={(props) => (
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

// authenticated route
export const PrivateRoute = ({
  component: Component,
  role: Role = [],
  ...rest
}) => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  return isAuthenticated ? (
      <Route {...rest} component={(props) => <Component {...props} />} />
  ) : (
    <Redirect to="/" from={rest.path} />
  );
};


// public route
// export const PublicRoute = ({ component: Component, ...rest }) => {
//   const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
//   return isAuthenticated ? (
//     <Redirect to="/customers" />
//   ) : (
//     <Route
//       {...rest}
//       component={(props) => (
//           <Component {...props} />
//       )}
//     />
//   );
// };