import {
  ForgotPassword,
  ResetPassword,
  ChagneEmailConfirmation,
  SignIn,
  SignUp,
  MyCoupons,
  // EmailVerification,
  MyProfile,
  UpdateProfile,
  ChangePassword
} from "../pages";

// Login or Sign in Routes
export const Auth = [
  { path: "/", exact: true, component: SignIn },
  { path: "/signup", exact: true, component: SignUp },
  { path: "/forgot-password", exact: true, component: ForgotPassword },
  { path: "/reset-password/:id", component: ResetPassword },
  { path: "/change-email", exact: true, component: ChagneEmailConfirmation },
];

// Public Routes
export const Public = [
  // { path: "/email-verification", exact: true, component: EmailVerification }
  // { path: "/test", exact: true, component: Test },
  // { path: "/all-test", exact: true, component: AllTestUser }
];

// Private Routes
export const Private = [
  {
    path: "/my-coupons",
    component: MyCoupons,
    exact: true,
  },
  {
    path: "/my-profile",
    component: MyProfile,
    exact: true,
  },
  {
    path: "/change-password",
    component: ChangePassword,
    exact: true,
  },
  {
    path: "/update-profile",
    component: UpdateProfile,
    exact: true,
  }
];
